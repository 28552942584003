import { Module } from 'vuex';
import { EventPayload, IDesign } from '@/service/ts-definitions';
import { updateProxyState } from '@/utils/updateProxyState';
import { RootStore } from '../rootStore';

export type ProjectStore = {
  data: IDesign;
};

type ProjectNamespace = 'project';

type SetModelMutation = EventPayload<
  `${ProjectNamespace}/setProject`,
  Partial<IDesign>
>;

export type ProjectMutations = SetModelMutation;

export const projectStore: Module<ProjectStore, RootStore> = {
  namespaced: true,
  state: () => ({
    data: {
      company_id: '',
      name: '',
      currency: 'USD',
      settings: {
        autoLogo: false,
        autoLogoData: {
          assetId: '',
          url: '',
        },
        pricingSettings: {
          config_type: 'default',
          custom_mark_up: '',
          mark_up: '0',
          price_type: 'customer',
          price_label: 'starting_at',
        },
        designMarkup: {
          mode: 'markup',
          percentage: '',
        },
      },
      is_draft: true,
      is_published: false,
      is_template: null,
      parent: '',
      product_id_field: 'part_id',
      pages: [],
      project_id: '',
      status: 'inactive',
      user: '',
      user_id: '',
      created_at: '',
      updated_at: '',
      short_id: '',
      slug: '',
    },
  }),
  mutations: {
    setProject(state, { payload }: SetModelMutation) {
      updateProxyState(state.data, payload);
    },
  },
};
