import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import '@/assets/styles/index.scss';
import '@zoomcatalog/design-system/dist/main.css';
import '@zoomcatalog/design-templates/dist/index.css';
import '@zoomcatalog/core-ui/dist/main.css';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { useSnifferServices } from '@zoomcatalog/composable';
import axios from 'axios';
import App from './App.vue';
import { setBaseUrl } from './config/axios';
import sentrySettings from './config/sentry';
import router from './router';
import { settings } from './settings/DynamicSettings';
import { rootStore, useCommit } from './store/rootStore';

async function prepare() {
  const env = import.meta.env.MODE;

  if (settings.ENABLE_MSW) {
    const { worker } = await import('./mocks/browser');
    worker.start({ onUnhandledRequest: 'bypass' });
    setBaseUrl(`${window.origin}/api`);
  } else {
    const commit = useCommit();
    const snifferService = await useSnifferServices({
      httpGetRequest: axios.get,
      snifferQuery: {
        enviroment: import.meta.env.MODE as any,
        servicesList: [
          'quickcanvas',
          'auth',
          'companies',
          'users',
          'assets',
          'sso',
        ],
      },
    });
    commit({
      type: 'sniffer/setServicesEndpoints',
      payload: snifferService.baseUrls,
    });
    setBaseUrl(`${snifferService.baseUrls.QC_API_ENDPOINT}canvas/v1`);
  }

  return env;
}

prepare().then((env) => {
  const app = createApp(App);
  if (!['development', 'testing'].includes(env)) {
    Sentry.init({ app, ...sentrySettings });
  }
  app.use(router).use(rootStore).use(VueQueryPlugin).mount('#root');
});
