import { Module } from 'vuex';
import { Company, Event, EventPayload } from '@/service/ts-definitions';
import { RootStore } from '../rootStore';

export interface AuthStore {
  isAuthorized: boolean;
  company?: Company;
}

type AuthNamespace = 'auth';

type SetAuthorizationMutation = Event<`${AuthNamespace}/setAuthorization`>;
type RemoveAuthorizationMutation =
  Event<`${AuthNamespace}/removeAuthorization`>;
type SetCompanyMutation = EventPayload<`${AuthNamespace}/setCompany`, Company>;

export type AuthMutations =
  | SetAuthorizationMutation
  | RemoveAuthorizationMutation
  | SetCompanyMutation;

export const authStore: Module<AuthStore, RootStore> = {
  namespaced: true,
  state: {
    isAuthorized: false,
  },
  mutations: {
    setAuthorization(state) {
      state.isAuthorized = true;
    },
    removeAuthorization(state) {
      state.isAuthorized = false;
    },
    setCompany(state, { payload }: SetCompanyMutation) {
      state.company = payload;
    },
  },
};
